(function ($) {

    var main_navigation_button = $('#js-navigation-button'),
        main_navigation = $('#js-navigation'),
        sub_navigation = $('#js-sub-navigation'),
        sub_navigation_button = $('#js-sub-navigation-button');

    main_navigation_button.on('click', function () {
        main_navigation_button.toggleClass('is-active');
        main_navigation.toggleClass('is-active');
    })

    sub_navigation_button.on('click', function () {
        sub_navigation.toggleClass('is-active');
    })

    //Gallery
    $('.gallery').modaal({
        type: 'image'
    });
    var slider = $('#js-slider');
    if(slider.length > 0){

        var flkty = new Flickity('#js-slider', {
            wrapAround: true,
            prevNextButtons: false,
            pageDots: true,
            cellAlign: 'center'
        });

        var $cellButtonGroup = $('.flickity-page-dots');
        var $cellButtons = $cellButtonGroup.find('.dot');


        flkty.on('select', function () {
            $cellButtons.filter('.is-selected')
                .removeClass('is-selected');
            $cellButtons.eq(flkty.selectedIndex)
                .addClass('is-selected');
        });

        // select cell on button click
        $cellButtonGroup.on('click', '.dot', function () {
            var index = $(this).index();
            flkty.select(index);
        });
    }


})(jQuery);




